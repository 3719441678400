import Img from '../images/homebg.jpg';
export const consultants=
[
    {
        id:1,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    },
    {
        id:2,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    },
    {
        id:3,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    },
    {
        id:4,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    },
    {
        id:5,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    },
    {
        id:6,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    },
    {
        id:7,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    },
    {
        id:8,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    },
    {
        id:9,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    },
    {
        id:10,
        src:Img,
        name:'Name Consultant',
        branch:'Branch in IITM',
        instagram:'#',
        linkedin:'#',
        whatsapp:'#'
    }

]
